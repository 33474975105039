<template>
    <div>
        <span>{{ $t('shop.info_key') }} <el-link href="https://devdocs.prestashop.com/1.7/development/webservice/" target="_blank" type="primary">link</el-link></span>
        <br><br>
        <el-button type="warning" @click="showRequiredResources">{{ $t('shop.api_required_resources') }}</el-button>
        <div v-if="showRequiredResourcesList">
            <ul>
                <li v-for="(rules, index) in requiredResources" :key="index">{{ index }}
                    <span v-for="(rule, indexRule) in rules"
                          :key="indexRule"
                          :class="{'text-green': rule === 'GET', 'text-blue': rule === 'POST', 'text-orange': rule === 'PUT'}">{{ rule }} </span>
                </li>
            </ul>
        </div>
        <div v-if="rules.show">
            <p>{{ rules.message }}</p>
            <ul>
                <li v-for="(rules, index) in rules.requiredResources" :key="index">{{ index }}
                    <span v-for="(rule, indexRule) in rules"
                          :key="indexRule"
                          :class="{'text-green': rule === 'GET', 'text-blue': rule === 'POST', 'text-orange': rule === 'PUT'}">{{ rule }} </span>
                </li>
            </ul>
            <template v-if="Object.keys(rules.additionalResources).length > 0">
                <p>{{ $t('shop.api_additional_resources') }}</p>
                <ul>
                    <li v-for="(rules, index) in rules.additionalResources" :key="index">{{ rules }}</li>
                </ul>
            </template>
        </div>

        <div class="mini-separator"></div>
        <el-input :placeholder="$t('shop.name')" v-model="shop.name"></el-input>

        <div class="mini-separator"></div>
        <span>Format: <strong>https://website.ro/</strong></span>
        <el-input :placeholder="$t('shop.site_url')" v-model="shop.shopUrl"></el-input>

        <div class="mini-separator"></div>
        <el-input :placeholder="$t('shop.add_api_key')" v-model="shop.apiKey"></el-input>

        <template v-if="shop.connect">
            <div class="mini-separator"></div>
            <span>Pentru a activa sincronizarea do comenzi completati datele din tab-ul
                                    <a href="#" @click.prevent="openSettingsTab" style="color: blue">Settings</a>
                                </span>
        </template>

        <div class="mini-separator"></div>
        <template v-if="shop.name.length > 3 && shop.apiKey.length > 10 && shop.shopUrl.length > 10">
            <el-button @click="connect" type="primary">{{ $t('shop.check_conn') }}</el-button>
        </template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                rules: {
                    show: false,
                    message: '',
                    data: [],
                    additionalResources: {}
                },
                requiredResources: {},
                showRequiredResourcesList: false,
            };
        },
        props: {
            shop: {
                type: Object,
            },
            platform: {
                type: String,
            }
        },
        methods: {
            showRequiredResources() {
                this.showRequiredResourcesList = !this.showRequiredResourcesList;
                const countResources = Object.keys(this.requiredResources).length;
                if (countResources === 0) {
                    this.$store.dispatch('integrations/getRequiredResources', {
                        platform: this.platform || this.shop.platform,
                    })
                        .then((res) => {
                            if (res.error === 0) {
                                this.requiredResources = res.message.requiredResources;
                            }
                        }).catch(() => {
                    });
                }
            },
            connect() {
                localStorage.setItem('shopUrl', this.shop.shopUrl);
                this.rules = {
                    show: false,
                    message: '',
                    data: []
                };
                this.$store.dispatch('integrations/checkConnectionToShop', {
                    ...this.shop,
                    platform: this.platform
                })
                    .then((res) => {
                        if (res.error === true) {
                            this.$notify.error(this.$t('shop.conn_err'));
                            if (typeof res.message.requiredResources !== 'undefined') {
                                this.rules = {
                                    show: true,
                                    message: res.message.message,
                                    requiredResources: res.message.requiredResources,
                                    additionalResources: res.message.additionalResources,
                                };
                            }
                        }
                        if (res.error === 0) {
                            this.$router.push({
                                name: 'shopView',
                                params: {
                                    id: res.message.id
                                }
                            }).catch(() => {});
                        }
                    }).catch(() => {
                });
            },
            openSettingsTab() {
                this.activeTab = 'settings';
            }
        }
    };
</script>